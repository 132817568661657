import React, { useContext, useEffect } from 'react';
import { TelefoniaContext } from '../context';
import SideNav from '../components/SideNav';
import FormInput from '../components/FormInput';
import CardList from '../components/CardList';
import CardColumns from 'react-bootstrap/CardColumns'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';
import '../App.css';

const Painel = () => {
  const {
    handleClickSearch,
    setCollection,
    collection,
    loading,
    colaboradores,
    fetchSearch,
    setRedirect,
    showForm,
    setShowForm,
    fetchToConfirmLogin,
  } = useContext(TelefoniaContext);

  const showSearchInputs = () => (
    <Form>
        <div className="searchBar">
          <Form.Group controlId="formGridState">
            <Form.Label>
              Selecione por qual fila deseja buscar:
                <Form.Control as="select" value={collection} onChange={({ target }) => setCollection(target.value)}>
                  <option value="">Selecione Uma Fila</option>
                  <option value="financeiro">Financeiro</option>
                  <option value="manutencao">Suporte</option>
                  <option value="representantes">Representantes De Venda</option>
                  <option value="consultoria">Consultoria</option>
                  <option value="representantesPJ">Representantes PJ</option>
                  <option value="parceirosFornecedores">Parceiros Fornecedores</option>
                  <option value="colaboradores">Todos os colaboradores</option>
              </Form.Control >
            </Form.Label>
          </Form.Group>  
          <Form.Group>
            <Button
                type="button"
                onClick={handleClickSearch}
                style={{ marginLeft: 10, marginTop: 15 }}
              >
                Buscar
            </Button> 
          </Form.Group>
        </div>
        { showForm &&
  <Form.Group>
      <Button
        style={ { display: "block" } }
        variant="info"
        onClick={() => setShowForm(false) }
      >Voltar</Button>
  </Form.Group>
        }
    </Form>
  );

  const renderLoading = () => (
    <div className="loading">
      <Spinner animation="border" role="status"></Spinner>
    </div>
  );

  const getColaboradores = async () => {
    await fetchSearch('colaboradores');
  }
  
  async function verificaLogin(){
    await fetchToConfirmLogin();
  };

  useEffect( () => {
    verificaLogin();
    getColaboradores();
    setRedirect(false);
    localStorage.setItem('horarioDeTrabalho', JSON.stringify([]));
  }, []);

  return (

    <div className="nav-area">
     <SideNav />
      <div className="area-card">
      { showSearchInputs() }
      { loading ? renderLoading() : ( showForm ? <FormInput type="insert" /> : <CardColumns> <CardList /> </CardColumns>)}
      </div>
    </div>
  );
}

export default Painel