import React, { createContext, useState } from 'react';

export const TelefoniaContext = createContext();

export const TelefoniaProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidUser, setInvalidUser] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectLogout, setRedirectLogout] = useState(false);
  const [idToAdd, setIdToAdd] = useState();
  const [idToDelete, setIdToDelete] = useState('');
  const [idFila, setIdFilaRamal] = useState('');
  const [nome, setNome] = useState('');
  const [ramal, setRamal] = useState('');
  const [url, setUrl] = useState('');
  const [googleChatId, setGoogleChatId] = useState('');
  const [atendimentos, setAtendimentos] = useState('');
  const [offSet, setOffSet] = useState('');
  const [qtdLeads, setQtdLeads] = useState('');
  const [weekDay, setWeekDay] = useState(0);
  const [disableButton, setDisableButton] = useState(true);
  const [checkHorarioEdit, setCheckHorarioEdit] = useState(false);

  const [collection, setCollection] = useState('');
  const [colaboradores, setColaboradores] = useState();
  const [collectionName, setCollectionName] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [deleteInputs, setDeleteInputs] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [addCard, setShowAddCard] = useState(false);
  const [disableEditOption, setDisableEditOption] = useState(false);
  

  const endpoint = process.env.REACT_APP_DOMINIO_SERVIDOR + 'db';
  const endpointLogin = 'https://api-telefonia.lifelink.com.br/auth/login';

const fetchToLogin = () => {
  const body = {
    username: email,
    password,
  };

  const myHeadersToLogin = {
    // 'Accept': 'application/json',
    'Content-Type': 'application/json',
    'mode': 'no-cors'
  };

  fetch(endpointLogin, {
    headers: myHeadersToLogin,
    method: 'POST',
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .then((response) => {
      if(response.error) {
        setInvalidUser(true);
      } else {
      localStorage.setItem('Token', response.token);
      localStorage.setItem('user', JSON.stringify(response.user));
      fetchToConfirmLogin()
    }
    });
}

const fetchToConfirmLogin  = () => {
  let Bearer = `Bearer ${localStorage.getItem('Token')}`;
  let myHeaders = {
  // 'Accept': 'application/json',
  'Content-Type': 'application/json',
  'mode': 'no-cors',
  'authorization': Bearer,
};
fetch('https://api-telefonia.lifelink.com.br/front/verificaToken', {
  headers: myHeaders,
  method: 'GET',
})
  .then((res) => res.json())
  .then((obj) => {
    if (localStorage.getItem('Token') === obj.token) {
      setRedirectLogin(true);
    } else if (localStorage.getItem('Token') !== obj.token) {
      window.location.href = process.env.REACT_APP_DOMINIO;
    }
  })
}

  function fetchToLogout() {
    const user = JSON.parse(localStorage.getItem('user'));
    let Bearer = `Bearer ${ localStorage.getItem('Token') }`;
    const body = {
      'username': user.username,
    }
    const myHeaders = {
      // 'Accept': 'application/json',
      'Content-Type': 'application/json',
      'mode': 'no-cors',
      "authorization": Bearer,
    };

    fetch('https://api-telefonia.lifelink.com.br/auth/logout', {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then((response) => {
          localStorage.clear();
          window.location.href = process.env.REACT_APP_DOMINIO;      })
      .catch(error => alert(error));
  }
  
 const handleClickLogin = () => {
  fetchToLogin();
  }


  const fetchSearch = (name) => {
    const data = { collection: name, type: 'search' }
    fetch(endpoint, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((obj) => {
        setColaboradores(obj)
      });
  }

  const fetchInsert = (obj) => {
    const body = { collection: collectionName, obj, type: 'insert' }
    fetch(endpoint, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
      .then((response) => response.json())
      .then((obj) => {
        setShowForm(false);
        setRedirect(true);
      });
  }

  const fetchDelete = (idToDelete) => {
    const body = { collection: collectionName, data: { id: idToDelete }, type: 'delete' }
    fetch(endpoint, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
      .then((response) => response.json())
      .then((obj) => {
        setRedirect(true);
      });
  }

  const handleClickSearch = () => {
    setShowForm(false);
    setLoading(true);
    const data = { collection, type: 'search' }
    fetch(endpoint, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) })
      .then((response) => response.json())
      .then((obj) => {
        setSearchResults(obj)
        setLoading((false))
        setCollectionName(collection);
        setShowAddCard(true);
      });
  }

  //const horarioDeTrabalhoDefault = [{ numeroDoDiaDaSemana: 0, diaDaSemana: "Domingo", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 1, diaDaSemana: "Segunda-Feira", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 2,  diaDaSemana: "Terça-Feira", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 3, diaDaSemana: "Quarta-Feira", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 4,  diaDaSemana: "Quinta-Feira", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 5, diaDaSemana: "sexta-Feira", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }, { numeroDoDiaDaSemana: 6, diaDaSemana: "Sábado", horaDeFimIntervalo: 13, horaDeFimTrabalho: 18, horaDeInicioIntervalo: 12, horaDeInicioTrabalho: 8, minutoDeFimIntervalo: 0, minutoDeFimTrabalho: 0, minutoDeInicioIntervalo: 0, minutoDeInicioTrabalho: 0 }]


  const nextDayHorarioTrabalho = () => {
    let workTime = [];
    if (localStorage.getItem('horarioDeTrabalho') === null) {
      localStorage.setItem('horarioDeTrabalho', JSON.stringify([]));
    } else {
      workTime = JSON.parse(localStorage.getItem('horarioDeTrabalho'))
    }
    if(workTime.length <= 6) {
      workTime.push(objHorarioTrabalho);
      setWeekDay((prev) => prev + 1);
      localStorage.setItem('horarioDeTrabalho', JSON.stringify(workTime))
      if (weekDay === 6) {
        setDisableButton(false);
      }
    }
  }

  const [ horaDeFimIntervalo, setHoraDeFimIntervalo ] = useState(0);
  const [ horaDeFimTrabalho, setHoraDeFimTrabalho ] = useState(0);
  const [ horaDeInicioIntervalo, setHoraDeInicioIntervalo ] = useState(0);
  const [ horaDeInicioTrabalho, setHoraDeInicioTrabalho ] = useState(0);
  const [ minutoDeFimIntervalo, setMinutoDeFimIntervalo ] = useState(0);
  const [ minutoDeFimTrabalho, setMinutoDeFimTrabalho ] = useState(0);
  const [ minutoDeInicioIntervalo, setMinutoDeInicioIntervalo ] = useState(0);
  const [ minutoDeInicioTrabalho, setMinutoDeInicioTrabalho ] = useState(0);

  const objHorarioTrabalho = 
  {
    numeroDoDiaDaSemana: weekDay,
    horaDeFimIntervalo,
    horaDeFimTrabalho,
    horaDeInicioIntervalo,
    horaDeInicioTrabalho,
    minutoDeFimIntervalo,
    minutoDeFimTrabalho,
    minutoDeInicioIntervalo,
    minutoDeInicioTrabalho,
  }

  const insertObj = {
    idFilaRamal: idFila,
    nome: nome,
    ramal: ramal,
    urlGoogleChat: url,
    idGoogleChat: googleChatId,
    atendimentos: 0,
  }

  const handleChange = ({ target }) => {
    const { name, value } = target;
    if (name === 'idFilaRamal') setIdFilaRamal(value);
    if (name === 'nome') setNome(value);
    if (name === 'ramal') setRamal(value);
    if (name === 'urlGoogleChat') setUrl(value);
    if (name === 'atendimentos') setAtendimentos(value);
    if (name === 'offSet') setOffSet(value);
    if (name === 'qtdLeads') setQtdLeads(value);
    if (name === 'idGoogleChat') setGoogleChatId(value);
  };

  
  const handleClickEdit = () => {
    let objToEdit = {};
    if (collectionName !== 'colaboradores' && collectionName !== 'representantes') {
      objToEdit = {
        ...insertObj,
        atendimentos: atendimentos,
        offset_atendimentos: offSet,
        ordem_encaminhamento: 1,
      }
    }
    if (collectionName === 'representantes') {
      objToEdit = {
        ...insertObj,
        atendimentos: atendimentos,
        offset_qntleads: offSet,
        quantidade_leads: qtdLeads,
        ordem_encaminhamento: 1,
      }
    }
    if (collectionName === 'colaboradores') {
      objToEdit = { ...insertObj }
    }
    const workTime = JSON.parse(localStorage.getItem('horarioDeTrabalho'));
    objToEdit.horarioTrabalho = workTime;
    fetchDelete(idToDelete); 
    fetchInsert(objToEdit);
    }

  const handleClickInsert = () => {
    let colaborador = colaboradores.find((element) => element._id === idToAdd);
    if (colaborador === undefined && colaboradores.length > 0) {
      colaborador = Object.assign(colaboradores[0], colaborador);
    }
    if (collectionName === '') setCollectionName(collection);


    if (collectionName !== 'colaboradores' && collectionName !== 'representantes') {
      let objToAdd = {}
      if (searchResults === undefined || searchResults.length === 0) {
        objToAdd = {
          ...colaborador,
          atendimentos: 0,
          offset_atendimentos: 0,
          ordem_encaminhamento: 1
        }
      } else {
      const offSetAtendimentos = searchResults.sort((a, b) => b.atendimentos - a.atendimentos);
        objToAdd = {
          ...colaborador,
          atendimentos: 0,
          offset_atendimentos: offSetAtendimentos[0].atendimentos,
          ordem_encaminhamento: (searchResults.length + 1),
        };
      }

      delete objToAdd._id;
      fetchInsert(objToAdd);
    } else if (collectionName === 'representantes') {
      let objToAdd = {}
      if (searchResults === undefined || searchResults.length === 0) {
        objToAdd = {
          ...colaborador,
          atendimentos: 0,
          offset_qntleads: 0,
          quantidade_leads: 0,
          ordem_encaminhamento: 1
        }
      } else {
        const offSetLeads = searchResults.sort((a, b) => (b.quantidade_leads + b.offset_qntleads) - (a.quantidade_leads + a.offset_qntleads));
        objToAdd = {
          ...colaborador,
          atendimentos: 0,
          offset_qntleads: 0,
          quantidade_leads: offSetLeads[0].quantidade_leads,
          ordem_encaminhamento: (searchResults.length + 1),
        };
      }
 
      delete objToAdd._id;
      fetchInsert(objToAdd);
    } else if(collectionName === 'colaboradores') {
      const workTime = JSON.parse(localStorage.getItem('horarioDeTrabalho'));
      insertObj.horarioTrabalho = workTime;
      fetchInsert(insertObj)
      setIdFilaRamal('');
      setNome('');
      setRamal('');
      setUrl('');
      localStorage.setItem('horarioDeTrabalho', JSON.stringify([]));
      setDisableButton(true);
    }
  }

  const handleClickButtonAdd = () => {
    if (collection === 'colaboradores') {
      setShowForm(true);
    } else {
      setShow(true)
    }
  }

  const handleClickAlert = ({ target }) => {
    const { value } = target;
    if (value === 'sim') {
      fetchDelete(idToDelete);
    }
    setShowAlert(false);
  }

  const handleClickDelete = ({ target }) => {
    const { id } = target;
    setShowAlert(true);
    setIdToDelete(id)
  }

  const contextValue = {
    password,
    email,
    setEmail,
    setPassword,
    handleClickInsert,
    handleClickEdit,
    handleClickSearch,
    collection,
    setCollection,
    deleteInputs,
    setDeleteInputs,
    handleClickDelete,
    searchResults,
    loading,
    collectionName,
    setShow,
    showModal,
    fetchSearch,
    colaboradores,
    idToAdd,
    setIdToAdd,
    redirect,
    setRedirect,
    showAlert,
    handleClickAlert,
    handleChange,
    idFila,
    nome,
    ramal,
    url,
    handleClickButtonAdd,
    showForm,
    setShowForm,
    setIdFilaRamal,
    setNome,
    setRamal,
    setUrl,
    atendimentos,
    setAtendimentos,
    offSet,
    setOffSet,
    qtdLeads,
    setQtdLeads,
    insertObj,
    setIdToDelete,
    weekDay,
    setWeekDay,
    horaDeFimIntervalo,
    setHoraDeFimIntervalo,
    horaDeFimTrabalho,
    setHoraDeFimTrabalho,
    horaDeInicioIntervalo,
    setHoraDeInicioIntervalo,
    horaDeInicioTrabalho,
    minutoDeFimIntervalo,
    setMinutoDeFimIntervalo,
    minutoDeFimTrabalho,
    setMinutoDeFimTrabalho,
    minutoDeInicioIntervalo,
    setMinutoDeInicioIntervalo,
    minutoDeInicioTrabalho,
    setMinutoDeInicioTrabalho,
    setHoraDeInicioTrabalho,
    nextDayHorarioTrabalho,
    disableButton,
    checkHorarioEdit,
    setCheckHorarioEdit,
    handleClickLogin,
    invalidUser,
    redirectLogin,
    fetchToLogout,
    redirectLogout,
    setRedirectLogout,
    fetchToConfirmLogin,
    setDisableEditOption,
    disableEditOption,
    setCollectionName,
    addCard,
    googleChatId
  };

  return (
    <TelefoniaContext.Provider value={contextValue}>
      { children}
    </TelefoniaContext.Provider>
  );
}