import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TelefoniaProvider } from './context';
import Login from './pages/Login';
import Painel from './pages/Painel';
import Edit from './pages/Edit';
import Emergencia from './pages/Emergencia';
import FilaErro from './pages/FilaErro';
import StatusFilas from './pages/StatusFilas';

function App() {
  return (
    <TelefoniaProvider>
    <div className="App">
      <Switch>
        <Route path="/edit/:collection/:id" component={ Edit } />
        {/* <Route path="//:id" component={ Details } /> */}
        <Route path="/painel" component={ Painel } />
        <Route path="/emergencia" component={ Emergencia } />
        <Route path="/FilaErro" component={ FilaErro } />
        <Route path="/statusFilas" component={ StatusFilas } />
        <Route exact path= "/" component={ Login } />
      </Switch>
    </div>
    </TelefoniaProvider>
  );
}

export default App;
